/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { toGregorian, jalaaliToDateObject } from "jalaali-js";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  CircularProgress,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InstagramIcon from "@mui/icons-material/Instagram";
import LogoutIcon from "@mui/icons-material/Logout";

import FullBody from "../../statics/IMG_29891.jpg";
import Aerobic from "../../statics/IMG_29931.jpg";
import Both from "../../statics/IMG_29901.jpg";
import Corrective from "../../statics/IMG_68471.jpg";
import axios from "../../common/axiosPrivate";
import {
  numericDateToAlphebet,
  numericDateTimeToAlphebet,
} from "../../statics/CONST";
import theme from "../../theme";
import axiosPublic from "../../common/axiosPublic";

const styles = {
  cntnr: {
    minHeight: "calc(100vh - 14.85rem)",
    mt: "2rem",
    color: "#014347",
  },
  widthLmt: {
    maxWidth: "62.5rem",
    m: "1rem",
    rowGap: "0.75rem",
  },
  contentRowsCntnr: {
    mb: "1rem",
    // dispay: "inline",
  },
  title: {
    fontSize: { xs: "1.65rem", sm: "2rem" },
    fontWeight: 750,
  },
  icon: {
    fontSize: "2rem",
    mr: "0.5rem",
  },
  mark: css`
    color: ${theme.palette.secondary.dark};
    background: linear-gradient(180deg, transparent 40%, #7cf2f7 0);
    margin: 0 0.2rem;
  `,
  packCntnr: {},
  packPpr: {
    color: "#014347",
    transition: "box-shadow 0.2s linear 0s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000070",
      // to change the style of its child (background image)
      "& #backgroundImg": {
        boxShadow: "inset 0 0 0 1000px rgba(4, 35, 36, 0.1)",
      },
    },
  },
  packImageCntnr: {
    height: "180px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "4px 4px 0 0",
    boxShadow: "inset 0 0 0 1000px rgba(4, 30, 31, 0.3)",
    transition: "box-shadow 0.2s linear 0s",
  },
  packDetailsCntnr: {
    p: "0.75rem",
  },
  packTitle: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: 600,
    mb: "0.75rem",
  },
  packDetailsRows: {
    mb: "0.75rem",
  },
  packBoldTxt: {
    fontWeight: 600,
  },
  howToTxt: {
    textAlign: "justify",
  },
  repurchaseBtnCntnr: {
    // mb: "0.75rem",
  },
  repurchaseBtn: {
    color: "#fff",
    width: "100%",
    fontWeight: 600,
  },
  bothRepurchaseBtn: {
    color: "#fff",
    minWidth: { xs: "100%", md: "10rem" },
  },
  menuItems: {
    color: "#014347",
  },
  link: css`
    text-decoration: none;
    color: #014347;
  `,
  instaBtn: {
    color: "#014347",
    borderColor: "#014347",
    width: "100%",
  },
  instaBtnLnk: css`
    text-decoration: none;
    color: #014347;
    width: 100%;
  `,
  idTxt: {
    fontFamily: "Arial, sans-serif !important",
    marginLeft: "0.15rem",
    textAlign: "right",
    direction: "rtl",
    display: "inline-block",
  },
  editCntnr: {
    mt: { xs: "3rem", md: "4rem" },
  },
  txtFld: {
    width: { xs: "100%", md: "30rem" },
    "& .MuiInputBase-input": {
      fontFamily: "Arial, sans-serif !important",
    },
    // input: {
    //   color: "red",
    //   "&::placeholder": {
    //     opacity: 1,
    //   },
    // },
  },
  editBtn: {
    width: { xs: "100%", md: "13rem" },
    color: "#fff",
  },
  originalPrice: css`
    text-decoration: line-through;
    font-size: 0.85rem;
    margin: 0 0.5rem;
  `,
  finalPrice: css`
    font-weight: 600;
    font-size: 1.05rem;
    margin: 0 0.5rem;
  `,
  purchaseBoth: {
    m: "1rem 0 2rem",
    fontSize: "1.4rem",
    fontWeight: 600,
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  },
  purchaseBothImportant: {
    background: "linear-gradient(180deg, transparent 40%, #7cf2f7 0)",
    fontWeight: 750,
    display: "inline-block",
    fontSize: "1.5rem",
  },
  telLink: css`
    text-decoration: none;
    margin: 0 0.25rem;
    color: ${theme.palette.primary.main};
    // color: #7cf2f7;
    text-align: right;
    direction: rtl;
  `,

  r1_iframe_embed: css`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-top: 56.25%;
  `,
  r1_iframe_embed_iframe: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border: 0 #ffffff none;
  `,
};

const streamList = {
  fullbody: (
    <>
      <Grid2
        xs={12}
        sx={{
          fontWeight: 700,
          fontSize: "1.25rem",
          mt: "1.5rem",
          mb: "0.5rem",
        }}
      >
        دوره فول بادی
      </Grid2>

      <div css={styles.r1_iframe_embed}>
        <iframe
          src="https://player.arvancloud.ir/index.html?config=https://doctormehrdad.arvanlive.ir/fullbody/origin_config.json&skin=shaka"
          css={styles.r1_iframe_embed_iframe}
          name="دوره فول بادی"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
    </>
  ),
  aerobic: (
    <>
      <Grid2
        xs={12}
        sx={{
          fontWeight: 700,
          fontSize: "1.25rem",
          mt: "2rem",
          mb: "0.5rem",
        }}
      >
        دوره هوازی
      </Grid2>
      <div css={styles.r1_iframe_embed}>
        <iframe
          src="https://player.arvancloud.ir/index.html?config=https://doctormehrdad.arvanlive.ir/cardio/origin_config.json&skin=shaka"
          css={styles.r1_iframe_embed_iframe}
          name="دوره هوازی"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
    </>
  ),
  corrective: (
    <>
      <Grid2
        xs={12}
        sx={{
          fontWeight: 700,
          fontSize: "1.25rem",
          mt: "2rem",
          mb: "0.5rem",
        }}
      >
        دوره اصلاحی
      </Grid2>
      <div css={styles.r1_iframe_embed}>
        <iframe
          src="https://player.arvancloud.ir/index.html?config=https://doctormehrdad.arvanlive.ir/corrective/origin_config.json&skin=shaka"
          css={styles.r1_iframe_embed_iframe}
          name="دوره اصلاحی"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      </div>
    </>
  ),
};

export default function ClientDashboard() {
  const [data, setData] = useState({
    editedId: "",
    is_user_info_fetched: false,
    is_packs_info_fetched: false,
    is_prices_fetched: false,
    user_info: {},
    packs_info: {},
    prices: {},
    has_fullbody: false,
    has_aerobic: false,
    has_corrective: false,
  });
  const [staticData, setStaticData] = useState({
    // ! page IDs must be changed
    fullbody: {
      name: "فول‌بادی",
      enName: "fullbody",
      packPage: "Fullbody",
      image: FullBody,
    },
    aerobic: {
      name: "هوازی",
      enName: "aerobic",
      packPage: "Cardio",
      image: Aerobic,
    },
    corrective: {
      name: "اصلاحی",
      enName: "corrective",
      packPage: "corrective",
      image: Corrective,
    },
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const handleChange = (e) => {
    setData({ ...data, editedId: e.target.value });
  };

  const redirectToPurchaseBoth = async () => {
    await axios
      .post("/accounts/logout/")
      .then((res) => {})
      .catch((err) => {});
    localStorage.removeItem("token");
    navigate("/register?p=both");
  };

  const handleGetUserInfo = async () => {
    await axios
      .get("/accounts/user_info/", {})
      .then((res) => {
        if (res.status === 200) {
          setData((prevState) => {
            return {
              ...prevState,
              user_info: res.data.data,
              is_user_info_fetched: true,
            };
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/register");
        }
      });
  };

  const handleGetData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await axios
        .get("/accounts/user_packages/", {})
        .then((res) => {
          if (res.status === 200) {
            setData((prevState) => {
              return {
                ...prevState,
                packs_info: res.data.data,
                is_packs_info_fetched: true,
              };
            });
            handleGetUserInfo();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/register");
          }
          // setIsLoggedIn(false);
          // navigate("/register");
        });
    } else {
      navigate("/register");
    }
  };

  const handleFetchPrice = async () => {
    await axiosPublic
      .get(
        `accounts/purchasable_price/?phone_number=${data.user_info.phone_number}`
      )
      .then((res) => {
        setData((prevState) => {
          return {
            ...prevState,
            prices: res.data,
            is_prices_fetched: true,
          };
        });
      })
      .catch((err) => {
        // setMuiAlert({
        //   show: true,
        //   type: "error",
        //   message: "لطفا دوباره تلاش کنید",
        // });
        setData((prevState) => {
          return {
            ...prevState,
            is_prices_fetched: true,
          };
        });
      });
  };

  const handleRecharge = async (pack) => {
    let pack_fa = "";

    if (pack === "aerobic") {
      pack_fa = "هوازی";
    } else if (pack === "fullbody") {
      pack_fa = "فول‌بادی";
    } else if (pack === "both") {
      pack_fa = "فول‌بادی + هوازی";
    } else if (pack === "corrective") {
      pack_fa = "اصلاحی";
    }

    if (data.prices.invites >= 5) {
      navigate("/verify-purchase?Status=OK");
    } else {
      const requestData = {
        package: pack,
        description: `حق اشتراک شرکت در دوره ${pack_fa} دکتر مهرداد`,
        callback_url: "https://doctormehrdad.com/verify-purchase",
      };

      await axios
        .post("accounts/recharge_gateway_info/", requestData)
        .then((res) => {
          // Set local storage and redirect
          if (res.status === 200) {
            window.location.href = `https://www.zarinpal.com/pg/StartPay/${res.data.data.authority}`;
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setMuiAlert({
              show: true,
              type: "error",
              message: "لطفا برای پرداخت دوباره تلاش کنید",
            });
          } else if (err.response.status === 403) {
            if (err.response.data.message === "user has already purchased") {
              setMuiAlert({
                show: true,
                type: "error",
                message: "شما قبلا این دوره را تمدید کرده‌اید",
              });
            } else if (
              err.response.data.message === "user has no active package"
            ) {
              setMuiAlert({
                show: true,
                type: "error",
                message:
                  "تمدید دوره فقط برای کاربرانی که دوره‌ی فعال دارند امکان‌پذیر است",
              });
            }
          }
        });
    }
  };

  const handleEditInstaId = async () => {
    if (data.editedId.length) {
      await axios
        .put("/accounts/user_insta_id/", { instagram_id: data.editedId })
        .then((res) => {
          if (res.status === 200) {
            setData((prevState) => {
              return { ...prevState, user_info: res.data.data };
            });
            setMuiAlert({
              show: true,
              type: "success",
              message: "آیدی شما با موفقیت ویرایش شد",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/register");
          }
          setMuiAlert({
            show: true,
            type: "error",
            message: "لطفا دوباره تلاش کنید",
          });
        });
    }
  };

  const handleLogout = async () => {
    setAnchorEl(null);

    await axios
      .post("/accounts/logout/")
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    if (data.is_packs_info_fetched) {
      let today = new Date();

      data.packs_info.map((item) => {
        let dateSliced = item.start_date.split("-");
        let grStartDate = jalaaliToDateObject(
          parseInt(dateSliced[0]),
          parseInt(dateSliced[1]),
          parseInt(dateSliced[2])
        );

        // let grStartDate = jalaaliToDateObject(1403, 8, 18);

        if (grStartDate <= today) {
          if (item.category === "fullbody") {
            setData((prevState) => {
              return { ...prevState, has_fullbody: true };
            });
          } else if (item.category === "aerobic") {
            setData((prevState) => {
              return { ...prevState, has_aerobic: true };
            });
          } else if (item.category === "corrective") {
            setData((prevState) => {
              return { ...prevState, has_corrective: true };
            });
          }
        }
      });
    }
  }, [data.is_packs_info_fetched]);

  useEffect(() => {
    if (data.is_user_info_fetched && data.packs_info.length) {
      handleFetchPrice();
    }
  }, [data.is_user_info_fetched, data.packs_info]);

  useEffect(() => {
    if (data.is_user_info_fetched && data.is_packs_info_fetched) {
      if (
        (data.packs_info.length && data.is_prices_fetched) ||
        !data.packs_info.length
      ) {
        setLoading(false);
      }
    }
  }, [
    data.is_user_info_fetched,
    data.is_packs_info_fetched,
    data.is_prices_fetched,
    data.packs_info,
  ]);

  // * refresh page once
  // useEffect(() => {
  //   const reloadCount = sessionStorage.getItem("reloadCount");
  //   if (reloadCount < 1) {
  //     sessionStorage.setItem("reloadCount", String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem("reloadCount");
  //   }
  // }, []);

  return (
    <Grid2
      container
      xs={12}
      justifyContent={"center"}
      // alignContent={"center"}
      alignItems={"center"}
      sx={styles.cntnr}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid2 container xs={12} sx={styles.widthLmt}>
          <Grid2 container xs={12} justifyContent={"flex-end"}>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Link to="/" css={styles.link}>
                <MenuItem onClick={handleCloseMenu}>
                  <ListItemIcon>
                    <HomeIcon fontSize="small" sx={styles.menuItems} />
                  </ListItemIcon>
                  <ListItemText>صفحه‌ی اصلی</ListItemText>
                </MenuItem>
              </Link>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" sx={styles.menuItems} />
                </ListItemIcon>
                <ListItemText sx={styles.menuItems}>خروج</ListItemText>
              </MenuItem>
            </Menu>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            <Grid2 container alignContent={"center"}>
              <FitnessCenterIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>دوره‌های شما</Grid2>
          </Grid2>
          {data.packs_info.length > 0 ? (
            <>
              <Grid2
                // container
                alignContent={"center"}
                xs={12}
                sx={styles.contentRowsCntnr}
              >
                برای شرکت توی جلسات،
                <mark css={styles.mark}>
                  با همون صفحه‌ای که باهاش ثبت‌نام کردید
                  <Grid2 css={styles.idTxt}>
                    ({data.user_info.instagram_id})
                  </Grid2>
                  ،
                </mark>
                به پیج باشگاه‌های زیر در اینستاگرام درخواست بدید، و منتظر بمونید
                تا تأیید بشه. (ممکنه چند روز زمان ببره، اما همه قبل از شروع دوره
                تأیید میشن، نگران نباشید)
              </Grid2>
              <Grid2
                // container
                alignContent={"center"}
                xs={12}
                sx={styles.contentRowsCntnr}
              >
                به هیچ وجه آیدی خودتون رو تا زمان تأیید شدن درخواست در پیج دوره،
                تغییر ندید. وگرنه ما نمی‌تونیم شما رو پیدا کنیم :)
              </Grid2>
              {data.prices.invites > 0 && (
                <Grid2 xs={12} sx={styles.contentRowsCntnr}>
                  <span style={{ fontWeight: 600, fontSize: "1.15rem" }}>
                    {data.prices.invites} نفر
                  </span>{" "}
                  به دعوت شما به باشگاه Fit With Doctor پیوسته‌اند. به همین دلیل
                  برای شما{" "}
                  <span style={{ fontWeight: 600, fontSize: "1.15rem" }}>
                    {data.prices.invites < 5
                      ? data.prices.invites * 20 + "%"
                      : "100%"}{" "}
                    تخفیف ویژه
                  </span>{" "}
                  در نظر گرفته‌ایم:
                </Grid2>
              )}
              <Grid2
                container
                xs={12}
                // spacing={{ xs: 3, md: 5 }}
                gap={{ xs: 3, md: 5 }}
                alignItems="flex-start"
                justifyContent={"center"}
              >
                {data.packs_info.length > 0 &&
                  data.packs_info[0].is_repurchasable && (
                    // <Link href="/register?p=both" css={styles.link}>
                    // <Grid2
                    //   xs={12}
                    //   sx={styles.purchaseBoth}
                    //   onClick={redirectToPurchaseBoth}
                    // >
                    //   جهت استفاده از تخفیف، برای ثبت‌نام در{" "}
                    //   <Grid2 sx={styles.purchaseBothImportant}>هر دو دوره</Grid2>{" "}
                    //   اینجا کلیک کنید.
                    // </Grid2>
                    // </Link>
                    <Grid2 xs={12} md={4} sx={styles.packCntnr}>
                      <Paper sx={styles.packPpr}>
                        <Grid2 container xs={12} sx={{ width: "100%" }}>
                          <Grid2
                            id="backgroundImg"
                            xs={12}
                            sx={[
                              styles.packImageCntnr,
                              {
                                backgroundImage: `url(${Both})`,
                              },
                            ]}
                          />
                          <Grid2
                            container
                            xs={12}
                            justifyContent={"center"}
                            sx={styles.packDetailsCntnr}
                          >
                            <Grid2 xs={12} sx={styles.packTitle}>
                              تمدید فول‌بادی + هوازی
                            </Grid2>
                            <Grid2
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              sx={{
                                justifyContent: "center",
                                textAlign: "center",
                                marginBottom: "0.75rem",
                              }}
                            >
                              در صورت تمدید هر دو دوره، از{" "}
                              <mark>تخفیف بزرگ</mark>
                              باشگاه بهره‌مند می‌شوید.
                            </Grid2>
                            <Grid2
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={[styles.packDetailsRows, styles.howToTxt]}
                            >
                              <Grid2>آیدی پیج دوره‌ها:</Grid2>
                              <Grid2
                                sx={{
                                  fontFamily: "Arial, sans-serif !important",
                                }}
                              >
                                <Grid2
                                  container
                                  justifyContent={"flex-end"}
                                  xs={12}
                                >
                                  {
                                    data.packs_info[0].newest_related_package
                                      .instagram_id
                                  }
                                </Grid2>
                                <Grid2
                                  container
                                  justifyContent={"flex-end"}
                                  xs={12}
                                >
                                  {data.packs_info.length > 1
                                    ? data.packs_info[1].newest_related_package
                                        .instagram_id
                                    : data.packs_info[0].newest_related_package.instagram_id
                                        .toLowerCase()
                                        .includes("fullbody")
                                    ? data.packs_info[0].newest_related_package.instagram_id
                                        .toLowerCase()
                                        .replace("fullbody", "cardio")
                                    : data.packs_info[0].newest_related_package.instagram_id
                                        .toLowerCase()
                                        .replace("cardio", "fullbody")}
                                  {/* {data.packs_info[1].newest_related_package.instagram_id} */}
                                </Grid2>
                              </Grid2>
                            </Grid2>
                            <Grid2 container xs={12} justifyContent={"center"}>
                              <span css={styles.originalPrice}>
                                998000 تومان
                              </span>
                              <span>‌با تخفیف</span>
                              <span css={styles.finalPrice}>
                                {data.prices.both / 10} تومان
                              </span>
                            </Grid2>
                            <Grid2 xs={12} sx={styles.repurchaseBtnCntnr}>
                              <Grid2>
                                <Button
                                  variant="contained"
                                  sx={styles.repurchaseBtn}
                                  onClick={redirectToPurchaseBoth}
                                >
                                  تمدید هر دو دوره
                                </Button>
                              </Grid2>
                              {/* </Tooltip> */}
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      </Paper>
                    </Grid2>
                  )}
                {data.packs_info.map((item) => {
                  return (
                    <Grid2 xs={12} md={4} sx={styles.packCntnr}>
                      <Paper sx={styles.packPpr}>
                        <Grid2 container xs={12} sx={{ width: "100%" }}>
                          <Grid2
                            id="backgroundImg"
                            xs={12}
                            sx={[
                              styles.packImageCntnr,
                              {
                                backgroundImage: `url(${
                                  staticData[item.category].image
                                })`,
                              },
                            ]}
                          />
                          <Grid2
                            container
                            xs={12}
                            justifyContent={"center"}
                            sx={styles.packDetailsCntnr}
                          >
                            <Grid2 xs={12} sx={styles.packTitle}>
                              {item.name}
                            </Grid2>
                            <Grid2
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              sx={styles.packDetailsRows}
                            >
                              <Grid2
                                container
                                xs={12}
                                justifyContent={"center"}
                                sx={styles.packBoldTxt}
                              >
                                از {numericDateToAlphebet(item.start_date).day}{" "}
                                {numericDateToAlphebet(item.start_date).month}{" "}
                                تا {numericDateToAlphebet(item.end_date).day}{" "}
                                {numericDateToAlphebet(item.end_date).month}
                              </Grid2>
                              {/* <Grid2
                                container
                                xs={12}
                                justifyContent={"center"}
                                sx={styles.packBoldTxt}
                              >
                                {item.remaining} روز مانده
                              </Grid2> */}
                            </Grid2>
                            <Grid2
                              container
                              xs={12}
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={[styles.packDetailsRows, styles.howToTxt]}
                            >
                              <Grid2>
                                <mark>
                                  پیج اینستاگرام این دوره را حتما دنبال کنید
                                </mark>
                              </Grid2>
                            </Grid2>
                            <Grid2
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={[styles.packDetailsRows, styles.howToTxt]}
                            >
                              <Grid2>آیدی پیج دوره:</Grid2>
                              <Grid2>{item.instagram_id}</Grid2>
                            </Grid2>
                            {/* <Grid2
                          container
                          xs={12}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={[styles.packDetailsRows, styles.howToTxt]}
                        >
                          <a
                            href={`https://instagram.com/${
                              staticData[item.category].packPage
                            }`}
                            css={styles.instaBtnLnk}
                          >
                            <Button
                              variant="outlined"
                              startIcon={<InstagramIcon />}
                              sx={styles.instaBtn}
                            >
                              ورود به پیج اینستاگرام دوره
                            </Button>
                          </a>
                        </Grid2> */}
                            {item.is_repurchasable && (
                              <Grid2
                                container
                                xs={12}
                                justifyContent={"center"}
                              >
                                {data.prices.invites > 0 && (
                                  <>
                                    <span css={styles.originalPrice}>
                                      {data.prices[
                                        [item.category] + "_original"
                                      ] / 10}{" "}
                                      تومان
                                    </span>
                                    <span>‌با تخفیف</span>
                                  </>
                                )}
                                <span css={styles.finalPrice}>
                                  {data.prices[item.category] / 10} تومان
                                </span>
                              </Grid2>
                            )}
                            <Grid2 xs={12} sx={styles.repurchaseBtnCntnr}>
                              {/* <Tooltip
                            title={
                              item.is_repurchasable && item.newest_related_package
                                ? `اتمام فرصت تمدید دوره تا ${
                                    numericDateTimeToAlphebet(
                                      item.newest_related_package
                                        .purchase_end_time
                                    ).day
                                  } ${
                                    numericDateTimeToAlphebet(
                                      item.newest_related_package
                                        .purchase_end_time
                                    ).month
                                  }`
                                : `شروع فرصت تمدید دوره از ${
                                    numericDateTimeToAlphebet(
                                      item.newest_related_package
                                        .recharge_start_time
                                    ).day
                                  } ${
                                    numericDateTimeToAlphebet(
                                      item.newest_related_package
                                        .recharge_start_time
                                    ).month
                                  }`
                            }
                            placement="top"
                          > */}
                              <Grid2>
                                <Button
                                  variant="contained"
                                  disabled={!item.is_repurchasable}
                                  sx={styles.repurchaseBtn}
                                  onClick={() => handleRecharge(item.category)}
                                >
                                  تمدید دوره
                                </Button>
                              </Grid2>
                              {/* </Tooltip> */}
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      </Paper>
                    </Grid2>
                  );
                })}

                {/* {data.packs_info.length > 1 &&
              data.packs_info[0].is_repurchasable &&
              data.packs_info[1].is_repurchasable && (
                // <Grid2
                //   container
                //   xs={12}
                //   justifyContent={"space-between"}
                //   sx={styles.packCntnr}
                // >
                //   <Grid2 xs={12} md>
                //     در صورتی که تمایل دارید هر دو دوره را تمدید کنید، و از تخفیف
                //     آن بهره‌مند شوید، از اینجا اقدام کنید
                //   </Grid2>
                //   <Grid2 xs={12} md={"auto"}>
                //     <Button
                //       variant="contained"
                //       disabled={!data.packs_info[0].is_repurchasable}
                //       sx={styles.bothRepurchaseBtn}
                //       onClick={handleRecharge("both")}
                //     >
                //       تمدید هر دو دوره
                //     </Button>
                //   </Grid2>
                // </Grid2>

                <Grid2 xs={12} md={4} sx={styles.packCntnr}>
                  <Paper sx={styles.packPpr}>
                    <Grid2 container xs={12} sx={{ width: "100%" }}>
                      <Grid2
                        id="backgroundImg"
                        xs={12}
                        sx={[
                          styles.packImageCntnr,
                          {
                            backgroundImage: `url(${
                              staticData[data.packs_info[0].category].image
                            })`,
                          },
                        ]}
                      />
                      <Grid2
                        container
                        xs={12}
                        justifyContent={"center"}
                        sx={styles.packDetailsCntnr}
                      >
                        <Grid2 xs={12} sx={styles.packTitle}>
                          فول‌بادی + هوازی
                        </Grid2>
                        <Grid2
                          container
                          xs={12}
                          justifyContent={"space-between"}
                          sx={styles.packDetailsRows}
                        >
                          <Grid2 sx={styles.packBoldTxt}>
                            از{" "}
                            {
                              numericDateToAlphebet(
                                data.packs_info[0].start_date
                              ).day
                            }{" "}
                            {
                              numericDateToAlphebet(
                                data.packs_info[0].start_date
                              ).month
                            }{" "}
                            تا{" "}
                            {
                              numericDateToAlphebet(data.packs_info[0].end_date)
                                .day
                            }{" "}
                            {
                              numericDateToAlphebet(data.packs_info[0].end_date)
                                .month
                            }
                          </Grid2>
                          <Grid2 sx={styles.packBoldTxt}>
                            {data.packs_info[0].remaining} روز مانده
                          </Grid2>
                        </Grid2>
                        <Grid2 container xs={12}>
                          <span css={styles.originalPrice}>998000 تومان</span>
                          <span>‌با تخفیف</span>
                          <span css={styles.finalPrice}>
                            {data.prices.both / 10} تومان
                          </span>
                        </Grid2>
                        <Grid2 xs={12} sx={styles.repurchaseBtnCntnr}>
                          <Tooltip
                            title={`اتمام فرصت تمدید دوره تا ${
                              numericDateTimeToAlphebet(
                                data.packs_info[0].newest_related_package
                                  .purchase_end_time
                              ).day
                            } ${
                              numericDateTimeToAlphebet(
                                data.packs_info[0].newest_related_package
                                  .purchase_end_time
                              ).month
                            }`}
                            placement="top"
                          >
                            <Grid2>
                              <Button
                                variant="contained"
                                sx={styles.repurchaseBtn}
                                onClick={() => handleRecharge("both")}
                              >
                                تمدید دوره
                              </Button>
                            </Grid2>
                          </Tooltip>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
              )} */}
              </Grid2>
              <Grid2 container xs={12} sx={{ rowGap: 1, mt: "2rem" }}>
                <Grid2
                  container
                  xs={12}
                  alignItems={"center"}
                  sx={styles.title}
                >
                  مشاهده لایو دوره‌ها
                  {/* <span
                    style={{
                      backgroundColor: "#c93c5d",
                      color: "#fff",
                      padding: "0.15rem 0.5rem",
                      fontSize: "0.95rem",
                      marginRight: "0.75rem",
                    }}
                  >
                    (آزمایشی)
                  </span> */}
                </Grid2>
                <Grid2 xs={12} sx={{}}>
                  رأس ساعت مشخص‌شده می‌توانید لایو جلسات را از این قسمت و یا از
                  طریق پیج اینستاگرام باشگاه مشاهده کنید.
                  <br />
                  لازم به ذکر است اطلاع‌رسانی های باشگاه فقط از طریق پیج
                  اینستاگرام باشگاه انجام خواهد شد.
                </Grid2>
                {/* <Grid2 xs={12} sx={{}}> 
                  در صورتی که لایو دوره در حال برگزاری باشه، می‌تونید اون رو در
                  همین جا یا در پیج اینستاگرامش به صورت لایو مشاهده کنید.
                </Grid2>
                <Grid2 xs={12} sx={{}}>
                  همچنین می‌تونید آرشیو ذخیره‌شده‌ی جلسات رو در پیج اینستاگرام
                  هر دوره مشاهده کنید.
                </Grid2> */}
                {/* {data.packs_info.map((item) => {
                  return streamList[item.category];
                })} */}
                {data.packs_info.some((e) => e.category === "fullbody") &&
                  data.has_fullbody &&
                  streamList.fullbody}
                {data.packs_info.some((e) => e.category === "aerobic") &&
                  data.has_aerobic &&
                  streamList.aerobic}
                {data.packs_info.some((e) => e.category === "corrective") &&
                  data.has_corrective &&
                  streamList.corrective}
              </Grid2>
            </>
          ) : (
            <Grid2 container xs={12} justifyContent={"center"} rowGap={2}>
              <Grid2 container xs={12} justifyContent={"center"}>
                در حال حاضر شما دارای دوره فعال نمی‌باشید.
              </Grid2>
              <Grid2 container xs={12} justifyContent={"center"}>
                <Grid2>
                  <Button
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      navigate("/", { state: { scroll: true } });
                    }}
                  >
                    ثبت نام در دوره
                  </Button>
                </Grid2>
              </Grid2>
            </Grid2>
          )}

          {/* <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={[styles.contentRowsCntnr, styles.editCntnr]}
          >
            <Grid2 container alignContent={"center"}>
              <EditIcon sx={styles.icon} />
            </Grid2>
            <Grid2 sx={styles.title}>ویرایش اطلاعات</Grid2>
          </Grid2>
          <Grid2
            container
            alignContent={"center"}
            xs={12}
            sx={styles.contentRowsCntnr}
          >
            برای ویرایش آیدی اینستاگرام خود، آیدی صحیح را در کادر زیر وارد کنید،
            و تایید را بزنید.
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <TextField
              variant="outlined"
              label="آیدی اینستاگرام شما"
              placeholder="آیدی صحیح اینستاگرام خود را وارد کنید"
              sx={[styles.txtFld, { mb: { xs: "1rem", md: 0 } }]}
              value={data.editedId}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              sx={styles.editBtn}
              onClick={handleEditInstaId}
            >
              ویرایش
            </Button>
          </Grid2> */}

          <Grid2 xs={12} sx={{ marginTop: "2rem" }}>
            * در صورت بروز هر گونه مشکل به شماره تماس
            <a href="Tel: +989921078890" css={styles.telLink}>
              <span style={{ unicodeBidi: "bidi-override", direction: "ltr" }}>
                +989921078890
              </span>
            </a>
            در واتساپ پیام دهید.
          </Grid2>
          <Snackbar
            open={muiAlert.show}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
              {muiAlert.message}
            </Alert>
          </Snackbar>
        </Grid2>
      )}
    </Grid2>
  );
}
