import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import LoginIcon from "@mui/icons-material/Login";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Close, Menu, Person, PersonOutline } from "@mui/icons-material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CallIcon from "@mui/icons-material/Call";

const styles = {
  headerOutCntnr: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.8)",
    p: "0.75rem",
    zIndex: 3,
    height: "4rem",
    // opacity: "0.9",
    transition: "background-color 0.3s",
    // transition: "background-color 0.3s",
  },
  headerCntnr: {
    color: "#ff0000",
    maxWidth: "75rem",
  },
  headerBtnCntnr: {
    columnGap: "0.75rem",
    display: { xs: "none", md: "flex" },
  },
  drawerBtnCntnr: {
    display: { xs: "flex", md: "none" },
  },
  floatingButton: {
    position: "fixed",
    zIndex: 3,
    bottom: "3rem",
    left: "3rem",
    backgroundColor: "#ffd500",
    "&:hover": {
      backgroundColor: "#d6b302",
    },
  },
};
export default function LoginButton(props) {
  const [offset, setOffset] = useState(0);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const buttons = [
    {
      title: "لایو",
      func: () => {
        // console.log("live stream");
        navigate("/dashboard");
      },
      icon: <LiveTvIcon />,
    },
    {
      title: "مجله",
      func: () => {
        // navigate("/blog/");
        window.location.href = "/blog/";
      },
      icon: <ImportContactsIcon />,
    },
    {
      title: "تماس با ما",
      func: () => {
        // window.location.href =
        //   "https://api.whatsapp.com/send?phone=989921078890&text=";
        window
          .open(
            "https://api.whatsapp.com/send?phone=989921078890&text=",
            "_blank"
          )
          .focus();
      },
      icon: <CallIcon />,
    },
  ];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 240 }} role="presentation" onClick={toggleDrawer(false)}>
      <Grid2
        container
        xs={12}
        justifyContent={"flex-end"}
        sx={{ my: "0.5rem", px: "0.5rem" }}
      >
        <IconButton onClick={toggleDrawer(false)}>
          <Close />
        </IconButton>
      </Grid2>
      <List>
        {buttons.map((item, index) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton onClick={item.func}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const scrollToPackages = () => {
    const packagesElement = document.getElementById("packages");
    packagesElement.scrollIntoView({ behavior: "smooth" });

    // var headerOffset = 80;
    // var elementPosition = packagesElement.getBoundingClientRect().top;
    // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    // window.scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth",
    // });
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (offset > 800) {
      document.getElementById("headerOutCntnr").style.backgroundColor =
        "rgba(34, 40, 49, 0.9)";
    } else {
      document.getElementById("headerOutCntnr").style.backgroundColor =
        "rgba(0,0,0,0.8)";
    }
  }, [offset]);

  return (
    <Grid2 container justifyContent={"center"} xs={12}>
      <Grid2
        id={"headerOutCntnr"}
        container
        justifyContent={"center"}
        xs={12}
        sx={styles.headerOutCntnr}
      >
        <Grid2
          container
          xs={12}
          justifyContent={"space-between"}
          sx={styles.headerCntnr}
        >
          <Grid2 container sx={styles.headerBtnCntnr}>
            {buttons.map((item) => {
              return (
                <Button variant="text" color={"white"} onClick={item.func}>
                  {item.title}
                </Button>
              );
            })}
          </Grid2>
          <Grid2 container sx={styles.drawerBtnCntnr}>
            <Button
              variant="text"
              color={"white"}
              startIcon={<Menu />}
              onClick={toggleDrawer(true)}
            >
              منو
            </Button>
          </Grid2>
          <Grid2>
            {props.isLoggedIn ? (
              <Link to="/dashboard">
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  endIcon={<PersonOutline />}
                >
                  حساب کاربری
                </Button>
              </Link>
            ) : (
              <Link to="/register">
                <Button
                  variant="contained"
                  sx={{ color: "#fff" }}
                  endIcon={<LoginIcon />}
                >
                  ورود
                </Button>
              </Link>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 container xs={12}>
        <Button
          variant="contained"
          size="small"
          onClick={scrollToPackages}
          sx={styles.floatingButton}
        >
          همین الآن ثبت نام کن!
        </Button>
      </Grid2>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </Grid2>
  );
}
