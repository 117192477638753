import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

const styles = {
  cntnr: {
    mt: { xs: "3.5rem", md: "5rem" },
    maxWidth: "75rem",
    px: "2rem",
  },
};

export default function IntroVideo() {
  return (
    <Grid2 container xs={12} justifyContent="center">
      <Grid2
        container
        justifyContent={"center"}
        alignItems={"center"}
        xs={12}
        sx={styles.cntnr}
      >
        <video style={{ width: "100%", maxWidth: 600 }} controls>
          <source
            src={"https://doctormehrdad.com/downloads/intro.mp4"}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Grid2>
    </Grid2>
  );
}
