/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosRetry from "axios-retry";
import axiosPublic from "../../common/axiosPublic";
import axiosPrivate from "../../common/axiosPrivate";
import theme from "../../theme";

const styles = {
  paper: {
    width: "100%",
    maxWidth: "32rem",
    m: { xs: "1rem", sm: 0 },
  },
  innerCntnr: {
    p: { xs: "1.5rem", md: "2rem" },
  },
  text: {
    mb: "1.5rem",
  },
  textFldCntnr: {
    mb: "1rem",
  },
  txtFld: {
    "& .MuiInputBase-input": {
      fontFamily: "Arial, sans-serif !important",
    },
  },
  idFld: {
    direction: "rtl",
    "& .MuiInputBase-input": {
      textAlign: "right",
    },
  },
  button: {
    color: "#fff",
    width: "100%",
  },
  descriptionTxt: {
    fontSize: "0.85rem",
    mb: "0.5rem",
  },
  originalPrice: css`
    text-decoration: line-through;
    font-size: 0.85rem;
    margin: 0 0.5rem;
  `,
  finalPrice: css`
    font-weight: 600;
    margin: 0 0.5rem;
  `,
  timerGrd: {
    textAlign: "center",
    fontSize: "16px",
  },
  supportNmbr: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
};

export default function RegisterForm(props) {
  const [data, setData] = useState({
    name: "",
    familyName: "",
    password: "",
    instaId: "",
    code: "",
    inviter: "",
    selectedPacks: [],
    totalPrice: 0,
    packsList: [
      {
        value: "fullbody",
        label: "فول‌بادی",
      },
      {
        value: "aerobic",
        label: "هوازی",
      },
      {
        value: "corrective",
        label: "تمرینات اصلاحی",
      },
    ],
    prices: {},
  });
  const [validation, setValidation] = useState({
    isNameValid: true,
    showNameError: false,
    isFamilyNameValid: true,
    showFamilyNameError: false,
    isPasswordValid: true,
    showPasswordError: false,
    isInstaIdValid: true,
    showInstaIdError: false,
    isCodeValid: true,
    showCodeError: false,
    isPacksValid: true,
    showPacksError: false,
    isValid: true,
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(180);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let tempValidation = {
      isNameValid: true,
      showNameError: false,
      isFamilyNameValid: true,
      showFamilyNameError: false,
      isPasswordValid: true,
      showPasswordError: false,
      isInstaIdValid: true,
      showInstaIdError: false,
      isCodeValid: true,
      showCodeError: false,
      isPacksValid: true,
      showPacksError: false,
      isValid: true,
    };

    if (!data.name) {
      tempValidation = {
        ...tempValidation,
        isNameValid: false,
        showNameError: true,
        isValid: false,
      };
    }
    if (!data.familyName) {
      tempValidation = {
        ...tempValidation,
        isFamilyNameValid: false,
        showFamilyNameError: true,
        isValid: false,
      };
    }
    if (data.password.length < 8) {
      tempValidation = {
        ...tempValidation,
        isPasswordValid: false,
        showPasswordError: true,
        isValid: false,
      };
    }
    if (!data.instaId) {
      tempValidation = {
        ...tempValidation,
        isInstaIdValid: false,
        showInstaIdError: true,
        isValid: false,
      };
    }
    if (data.code.length !== 6) {
      tempValidation = {
        ...tempValidation,
        isCodeValid: false,
        showCodeError: true,
        isValid: false,
      };
    }
    if (data.selectedPacks.length === 0) {
      tempValidation = {
        ...tempValidation,
        isPacksValid: false,
        showPacksError: true,
        isValid: false,
      };
    }
    setValidation((prevState) => {
      return { ...prevState, ...tempValidation };
    });
    return tempValidation;
  };

  const handleChange = (parameter) => (event) => {
    setData((prevState) => {
      if (parameter === "packs") {
        return {
          ...prevState,
          selectedPacks: event.target.value,
          totalPrice:
            event.target.value.length === 0
              ? 0
              : event.target.value.length === 1
              ? event.target.value[0].price
              : event.target.value.length === 2 &&
                (event.target.value.includes("fullbody") &&
                event.target.value.includes("aerobic")
                  ? // ? 30000
                    5990000
                  : event.target.value[0].price + event.target.value[1].price),
        };
      } else {
        return { ...prevState, [parameter]: event.target.value };
      }
    });
  };

  const handleClickShowPassword = () =>
    setValidation((previousState) => {
      return { ...previousState, showPassword: !validation.showPassword };
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  axiosRetry(axiosPrivate, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 1000; // time interval between retries
    },
    retryCondition: (error) => {
      // Conditional check the error status code
      if ([404, 408, 429, 500, 501, 503, 504].includes(error.response.status)) {
        return true;
      } else {
        return false; // Do not retry the others
      }
    },
  });

  const handleSetSelectedPacks = () => {
    if (props.packs) {
      setLoading(false);
      console.log("setting packs from url");
      // console.log(data);
      console.log(data.prices);
      if (props.packs === "fullbody") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[0]],
            totalPrice: data.prices.fullbody,
          };
        });
      } else if (props.packs === "aerobic") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[1]],
            totalPrice: data.prices.aerobic,
          };
        });
      } else if (props.packs === "both") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: data.packsList,
            totalPrice: data.prices.both,
          };
        });
        if (props.packs === "corrective") {
          setData((prevState) => {
            return {
              ...prevState,
              selectedPacks: [data.packsList[2]],
              totalPrice: data.prices.corrective,
            };
          });
        }
      }
    }
  };

  const handleFetchPrice = async () => {
    await axiosPublic
      .get("accounts/purchasable_price/")
      .then((res) => {
        console.log(res.data);
        setData((prevState) => {
          return {
            ...prevState,
            prices: res.data,
          };
        });
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "لطفا دوباره تلاش کنید",
        });
      });
  };

  const handleResend = async () => {
    if (timeLeft <= 0) {
      await axiosPublic
        .post("accounts/resend_code/", { phone_number: props.data.phoneNumber })
        // Handle the response from backend here
        .then((res) => {
          if (res.status === 200 && res.data.message === "code resent") {
            setTimeLeft(180);
            setMuiAlert({
              show: true,
              type: "success",
              message: "کد دوباره ارسال شد",
            });
          }
        })

        // Catch errors if any
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.message === "verified previously") {
              setMuiAlert({
                show: true,
                type: "info",
                message: "حساب کاربری قبلا تایید شده است",
              });
            } else if (
              err.response.data.message === "3 minutes should be passed"
            ) {
              setMuiAlert({
                show: true,
                type: "error",
                message: "باید سه دقیقه از ارسال کد قبلی بگذرد",
              });
            }
          } else if (err.response.status === 404) {
            setMuiAlert({
              show: true,
              type: "error",
              message: "این حساب کاربری وجود ندارد",
            });
          }
        });
    }
  };

  const sendInfoToGateway = async () => {
    let pack = "";
    let pack_fa = "";
    if (data.selectedPacks.length === 1) {
      pack = data.selectedPacks[0].value;
      if (pack === "aerobic") {
        pack_fa = "هوازی";
      } else if (pack === "fullbody") {
        pack_fa = "فول‌بادی";
      } else if (pack === "corrective") {
        pack_fa = "اصلاحی";
      }
    } else if (data.selectedPacks.length === 2) {
      pack = "both";
      pack_fa = "فول‌بادی + هوازی";
    }

    const requestData = {
      package: pack,
      description: `حق اشتراک شرکت در دوره ${pack_fa} دکتر مهرداد`,
      callback_url: "https://doctormehrdad.com/verify-purchase",
    };
    localStorage.setItem("price", data.totalPrice);
    localStorage.setItem(
      "package",
      data.selectedPacks.length === 1 ? data.selectedPacks[0].value : "both"
    );
    await axiosPrivate
      .post("accounts/gateway_info/", requestData)
      .then((res) => {
        // Set local storage and redirect
        if (res.status === 200) {
          window.location.href = `https://www.zarinpal.com/pg/StartPay/${res.data.data.authority}`;
          return { status: 200, data: res.data };
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "لطفا برای پرداخت دوباره تلاش کنید",
          });
          props.stateChanger("checkNumber");
          return { status: 400, data: err };
        } else if (err.response.status === 403) {
          // * It's logically impossible for a user that has not signed up before, to already have purchased a pack
          setMuiAlert({
            show: true,
            type: "error",
            message: "این دوره در حال حاضر برای شما فعال است",
          });
          navigate("/dashboard");
          return { status: 403, data: err };
        }
      });
  };

  const handleSubmit = async () => {
    setOpenDialog(false);

    let validationOutput = checkValidation();
    if (validationOutput.isValid) {
      let info = {
        phone_number: props.data.phoneNumber,
        password: data.password,
        name: data.name,
        last_name: data.familyName,
        instagram_id: data.instaId,
        verification_code: data.code,
      };
      if (data.inviter.length) {
        info = { ...info, inviter: data.inviter };
      }
      setButtonLoading(true);
      try {
        const registerResponse = await axiosPublic.post(
          "accounts/register/",
          info
        );

        if (
          registerResponse.status === 201 &&
          registerResponse.data.message === "user created successfully"
        ) {
          setMuiAlert({
            show: true,
            type: "success",
            message: "لطفا تا انتقال به درگاه بانکی صبر کنید",
          });
          setButtonLoading(false);
          localStorage.setItem("token", registerResponse.data.access);
          let gatewayStatus = await sendInfoToGateway();
        }
      } catch (err) {
        if (err.response.status === 400) {
          setButtonLoading(false);
          if (err.response.data.message === "invalid code") {
            setMuiAlert({
              show: true,
              type: "error",
              message: "کد وارد شده اشتباه است",
            });
          } else {
            setMuiAlert({
              show: true,
              type: "error",
              message: "اطلاعات وارد شده صحیح نمی‌باشد",
            });
            if (
              err.response.data.message === "previous step must be completed"
            ) {
              // return to checkNumber step
              props.stateChanger("checkNumber");
            }
          }
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    handleFetchPrice();
  }, []);

  useEffect(() => {
    handleSetSelectedPacks(); // Trigger handleSetSelectedPacks when data.prices changes
  }, [data.prices]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setMuiAlert({ ...muiAlert, show: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [muiAlert]);

  return (
    <Grid2 container xs={12} justifyContent={"center"}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={5} sx={styles.paper}>
          <Grid2 container xs={12} sx={styles.innerCntnr}>
            <Grid2 xs={12} sx={styles.text}>
              اطلاعات خود را تکمیل کنید
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                fullWidth
                label="نام"
                value={data.name}
                onChange={handleChange("name")}
                helperText={
                  validation.showNameError && "لطفا نام خود را وارد کنید"
                }
                error={!validation.isNameValid}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                fullWidth
                label="نام خانوادگی"
                value={data.familyName}
                onChange={handleChange("familyName")}
                helperText={
                  validation.showFamilyNameError &&
                  "لطفا نام خانوادگی خود را وارد کنید"
                }
                error={!validation.isFamilyNameValid}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                variant="outlined"
                type={validation.showPassword ? "text" : "password"}
                label="رمز عبور"
                value={data.password}
                fullWidth
                onChange={handleChange("password")}
                onKeyDown={handleKeyDown}
                helperText={
                  validation.showPasswordError &&
                  "لطفا یک پسورد معتبر با حداقل 8 کاراکتر انتخاب کنید"
                }
                sx={styles.txtFld}
                error={!validation.isPasswordValid}
                InputProps={{
                  endAdornment: data.password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {validation.showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                label="دوره"
                select
                fullWidth
                value={data.selectedPacks}
                onChange={handleChange("packs")}
                helperText={
                  validation.showPacksError && "لطفا دوره‌ی را انتخاب کنید"
                }
                error={!validation.isPacksValid}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((option) => (
                        <Chip key={option.value} label={option.label} />
                      ))}
                    </Box>
                  ),
                }}
              >
                {data.packsList.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                fullWidth
                label="آیدی دقیق اینستاگرام"
                value={data.instaId}
                onChange={handleChange("instaId")}
                helperText={
                  validation.showInstaIdError
                    ? "لطفا آیدی اینستاگرام خود را به صورت دقیق وارد کنید"
                    : "در صورت اشتباه وارد کردن آیدی، قادر به شرکت در لایوها نخواهید بود"
                }
                sx={[styles.txtFld, styles.idFld]}
                error={!validation.isInstaIdValid}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                fullWidth
                label="شماره تلفن معرف (اختیاری)"
                value={data.inviter}
                onChange={handleChange("inviter")}
                helperText={"در صورتی که معرف ندارید، این قسمت را خالی بگذارید"}
                sx={styles.txtFld}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                fullWidth
                label="کد احراز هویت"
                value={data.code}
                onChange={handleChange("code")}
                helperText={
                  validation.showCodeError
                    ? "لطفا کد ارسال شده به شماره تلفن‌تان را به شکل صحیح وارد کنید"
                    : "در صورت عدم دریافت کد، از قسمت پایین جهت دریافت مجدد کد اقدام کنید"
                }
                error={!validation.isCodeValid}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              مبلغ:
              {data.selectedPacks.length === 2 && (
                <span css={styles.originalPrice}>998000 تومان</span>
              )}
              {data.selectedPacks.length === 2 && <span>‌با تخفیف</span>}
              <span css={styles.finalPrice}>{data.totalPrice / 10} تومان</span>
            </Grid2>
            <Grid2 xs={12} sx={[styles.textFldCntnr, styles.timerGrd]}>
              {timeLeft ? (
                Math.floor(timeLeft / 60) +
                ":" +
                (timeLeft % 60 < 10 ? "0" + (timeLeft % 60) : timeLeft % 60) +
                " مانده تا دریافت مجدد کد"
              ) : (
                <Button variant="text" fullWidth onClick={handleResend}>
                  دریافت مجدد کد
                </Button>
              )}
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <LoadingButton
                variant="contained"
                size="large"
                loading={buttonLoading}
                // onClick={handleSubmit}
                onClick={() => {
                  setOpenDialog(true);
                }}
                sx={styles.button}
              >
                پرداخت
              </LoadingButton>
            </Grid2>
            <Grid2 xs={12} sx={styles.descriptionTxt}>
              * در صورتی که آیدی صفحه‌ی اینستاگرام خود را اشتباه وارد کنید، قادر
              به شرکت در لایوها نخواهید بود.
            </Grid2>
            <Grid2 xs={12} sx={styles.descriptionTxt}>
              ** اگر پس از چند بار تلاش، کد احراز هویت را دریافت نکردید، با یک
              شماره تلفن دیگر امتحان کنید.
            </Grid2>
            <Grid2 xs={12} sx={styles.descriptionTxt}>
              *** در صورت مواجهه با هرگونه مشکل در فرایند ثبت نام، به شماره{" "}
              <a href="Tel: +989921078890" style={styles.supportNmbr}>
                +989921078890
              </a>{" "}
              در واتساپ پیام دهید
            </Grid2>
          </Grid2>
          <Snackbar
            open={muiAlert.show}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
              {muiAlert.message}
            </Alert>
          </Snackbar>

          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>حواست باشه!</DialogTitle>
            <DialogContent>
              <p>
                بعد از اینکه پرداخت رو انجام دادی، حتما به پیج هایی که برات
                پیامک میشه توی اینستاگرام درخواست بده، تا بتونی توی دوره شرکت
                کنی!
              </p>
            </DialogContent>
            <DialogActions>
              <Grid2
                container
                xs={12}
                justifyContent={"center"}
                sx={{ px: "1rem", mb: "1rem" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  sx={styles.button}
                >
                  باشه!
                </Button>
              </Grid2>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </Grid2>
  );
}
