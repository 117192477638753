import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: "#7cf2f7",
      main: "#00ADB5",
      dark: "#014347",
    },
    secondary: {
      light: "#787f8a",
      main: "#393E46",
      dark: "#222831",
    },
    white:{
      light: "#fff",
      main: "#fff",
      dark: "#fff",
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      xm: 450,
      sm: 600,
      sd: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
