/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Paper,
  Alert,
  Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import axiosPublic from "../../common/axiosPublic";

const styles = {
  paper: {
    width: "100%",
    maxWidth: "32rem",
    m: { xs: "1rem", sm: 0 },
  },
  innerCntnr: {
    p: { xs: "1.5rem", md: "2rem" },
  },
  text: {
    mb: "1.5rem",
  },
  textFldCntnr: {
    mb: "1rem",
  },
  originalPrice: css`
    text-decoration: line-through;
    font-size: 0.85rem;
    margin: 0 0.5rem;
  `,
  finalPrice: css`
    font-weight: 600;
    margin: 0 0.5rem;
  `,
  button: {
    color: "#fff",
    width: "100%",
    mb: "1rem",
  },
  forgetBtn: {
    width: { xs: "100%", md: "auto" },
  },
};

export default function Login(props) {
  const [data, setData] = useState({
    phoneNumber: "",
    password: "",
  });
  const [validation, setValidation] = useState({
    isPhoneNumberValid: true,
    showPhoneNumberError: false,
    isPasswordValid: true,
    showPasswordError: false,
    showPassword: false,
    isValid: false,
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let tempValidation = {
      isPasswordValid: true,
      showPasswordError: false,
      isValid: true,
    };

    if (!data.password) {
      tempValidation = {
        ...tempValidation,
        isPasswordValid: false,
        showPasswordError: true,
        isValid: false,
      };
    }
    setValidation((prevState) => {
      return { ...prevState, ...tempValidation };
    });
    return tempValidation;
  };

  const handleChange = (parameter) => (event) => {
    setData((prevState) => {
      // if (parameter === "packs") {
      //   return {
      //     ...prevState,
      //     selectedPacks: event.target.value,
      //     totalPrice:
      //       event.target.value.length === 0
      //         ? 0
      //         : event.target.value.length === 1
      //         ? event.target.value[0].price
      //         : event.target.value.length === 2 && 5990000,
      //   };
      // } else {
        return { ...prevState, [parameter]: event.target.value };
      // }
    });
  };

  const handleClickShowPassword = () =>
    setValidation((previousState) => {
      return { ...previousState, showPassword: !validation.showPassword };
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };


  const handleSubmit = async () => {
    let validationOutput = checkValidation();
    if (validationOutput.isValid) {
      setButtonLoading(true);
      await axiosPublic
        .post("accounts/login/", {
          phone_number: props.data.phoneNumber,
          password: data.password,
        })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.message === "logged in successfully"
          ) {
            localStorage.setItem("token", res.data.access);
            setMuiAlert({
              show: true,
              type: "success",
              message: "ورود با موفقیت انجام شد",
            });
            setButtonLoading(false);
            navigate("/dashboard");
          }
        })

        // Catch errors if any
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.data.message === "invalid username or password"
          ) {
            setMuiAlert({
              show: true,
              type: "error",
              message: "شماره‌ی همراه یا رمز عبور صحیح نمی‌باشد",
            });
            setButtonLoading(false);
          }
        });
    }
  };

  const handleForgotPassword = async () => {
    await axiosPublic
      .post("accounts/send_password_reset_code/", {
        phone_number: props.data.phoneNumber,
      })
      .then((res) => {
        if (
          res.status === 200 &&
          (res.data.message === "code sent" ||
            res.data.message === "previous code is valid")
        ) {
          setMuiAlert({
            show: true,
            type: "success",
            message: "کد تأیید برای شما ارسال شد",
          });
          props.stateChanger("forgetPassword", props.data);
        }
      })

      // Catch errors if any
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.message === "password changed recently"
        ) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "رمز عبور به تازگی تغییر یافته‌است",
          });
        }
      });
  };

  // useEffect(() => {
  //   if (props.packs) {
      
  //     if (props.packs === "fullbody") {
  //       setData((prevState) => {
  //         return {
  //           ...prevState,
  //           selectedPacks: [data.packsList[0]],
  //           totalPrice: data.packsList[0].price,
  //         };
  //       });
  //     } else if (props.packs === "aerobic") {
  //       setData((prevState) => {
  //         return {
  //           ...prevState,
  //           selectedPacks: [data.packsList[1]],
  //           totalPrice: data.packsList[0].price,
  //         };
  //       });
  //     } else if (props.packs === "both") {
  //       setData((prevState) => {
  //         return {
  //           ...prevState,
  //           selectedPacks: data.packsList,
  //           totalPrice: 5990000,
  //         };
  //       });
  //     }
  //     else if (props.packs === "corrective") {
  //       setData((prevState) => {
  //         return {
  //           ...prevState,
  //           selectedPacks: [data.packsList[2]],
  //           totalPrice: data.packsList[0].price,
  //         };
  //       });
  //     }
  //   }
  // }, []);


  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setMuiAlert({ ...muiAlert, show: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [muiAlert]);

  return (
    <Grid2 container xs={12} justifyContent={"center"}>
      <Paper elevation={5} sx={styles.paper}>
        <Grid2 container xs={12} sx={styles.innerCntnr}>
          <Grid2 xs={12} sx={styles.text}>
            لطفا رمز عبور خود را وارد کنید
          </Grid2>
          <Grid2 xs={12} sx={styles.textFldCntnr}>
            <TextField
              variant="outlined"
              type={validation.showPassword ? "text" : "password"}
              label="رمز عبور"
              value={data.password}
              autoFocus
              fullWidth
              onChange={handleChange("password")}
              onKeyDown={handleKeyDown}
              helperText={
                validation.showPasswordError && "لطفا رمز عبور خود را وارد کنید"
              }
              error={!validation.isPasswordValid}
              InputProps={{
                endAdornment: data.password && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {validation.showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
          <Grid2 xs={12} sx={styles.buttonCntnr}>
            <LoadingButton
              variant="contained"
              size="large"
              loading={buttonLoading}
              onClick={handleSubmit}
              sx={styles.button}
            >
              ورود
            </LoadingButton>
          </Grid2>
          <Grid2
            container
            xs={12}
            justifyContent={"flex-end"}
            sx={styles.buttonCntnr}
          >
            <Button
              variant="text"
              onClick={handleForgotPassword}
              sx={styles.forgetBtn}
            >
              رمز خود را فراموشی کرده‌ام
            </Button>
          </Grid2>
        </Grid2>
        <Snackbar
          open={muiAlert.show}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
            {muiAlert.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Grid2>
  );
}
